.table{
    margin: 2px;
    width: 70%;
    height: auto;
    margin-bottom: 40px;
}
.form-control{
    width: 656px;
    height: 42px;
    padding: 4px, 8px, 4px, 8px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;

}

#inputField{
    border: 1px solid black;
    margin-bottom: 2%;
}

.table th{
    font-size: 14px;
}

.table td{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
    padding-right: 10px;
    max-width: 6.6rem ;
}

.deleteButton{
    margin-top: 0.5rem;
    background-color: #232674;
    color: white;
    border-radius: 10px;
}

.editBtn{
    background-color: #232674;
    color: white;
    border-radius: 10px;
}

.leftMarginBtn{
    margin-left: .5rem;
}
.searchBtn, .clearBtn{
    height: 2.5rem;
    border-radius: 10px;
    margin-left: 1rem;
    background-color: #234674;
    color: white;
}

.expandedColumn{
    max-width: 8.5rem !important;
}

.accepted, .callback, .follow-up{
    width: 65px;
height: 40px;
padding: 10px;
border-radius: 8px;
gap: 10px;
background: #E4FCEC;
display: flex;
align-items: center;
padding:4px;
}

.rejected, .closed{
    width: 75px;
height: 40px;
padding: 10px;
border-radius: 8px;
gap: 10px;
background: #ffecec;
display: flex;
align-items: center;
padding:4px;
}

.not-connected, .not-interested{
    width: 100px !important;
height: 40px;
padding: 10px;
border-radius: 8px;
gap: 10px;
background: #ffecec;
display: flex;
align-items: center;
padding:4px;
}

.language-barrier{
    width: 115px !important;
height: 40px;
padding: 10px;
border-radius: 8px;
gap: 10px;
background: #ffecec;
display: flex;
align-items: center;
padding:4px;
}