@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');



.login{
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
  background-position: center;
   overflow: hidden;
}
.login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url('../Assests/background.jpg');

    filter: blur(4px); /* Adjust the blur amount as needed */
    z-index: -1; /* Ensure the pseudo-element is behind the content */
  }

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position:absolute;
    
}
.heading{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading>img{
width: 4rem;
height: 4rem;
border-radius: 49px;
z-index: 3;
margin-top: 3rem;

}
.heading>h1{
    font-family: Racing sans One;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    z-index: 3;
    margin-top: -2rem;
}
.loginin{
    display: flex;
    flex-direction: column;
    width: 23rem;
    height: 23rem;


border-radius: 20px;
gap: 32px;
background-color: #FFFFFF;
z-index: 3;
margin-top: 1.5rem;
}
.welcome{
    margin: 3rem 3rem 1rem 3rem;
}
.credential{
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    margin-left: 3rem;
    height: 55%;
    margin-top: -25px;
}
.credential>input{
    margin-bottom: 2rem;
    height: 3rem;
     border-radius: 10px;
}
.credential>button{
   height: 3rem;
   border-radius: 10px;
   background-color:#232674;
    color: #FFFFFF;
   font-size: 18px;
}
.credential>button>svg{
    margin-left: 4px;
}
.email{
    background-color: #FFFFFF;
    width: 2.4rem;
    margin-top: -19px;
    margin-bottom: -12px;
    margin-left: 1rem;
    z-index: 5;
}
.pass{
    background-color: #FFFFFF;
    width: 4.1rem;
    margin-top: -19px;
    margin-bottom: -12px;
    margin-left: 1rem;
    z-index: 5;
}
.eye{
    position: relative;
    bottom: 4.1rem;
    left: 15rem;
    cursor: pointer;
}

label{
    position: relative;
    bottom: 1rem;
    margin-top: -1.5rem;
}


