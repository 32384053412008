.NewLeads{
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    
}
.form{
      display: flex;
      flex-direction: column;
      width: 70vw;
      height: 95vh;
      padding: 32px, 24px, 32px, 24px;
      border-radius: 50px;
      gap: 24px;
      border: 1;
      box-shadow:  0 8px 8px rgba(0, 0, 0, 0.1);
      overflow-x: hidden;
}
.contents{
    height: 80vh;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.headings{
    display: flex;
    margin: 1rem;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-between;

}
.headings>svg:hover{
    cursor: pointer;
}
.button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 95%;
    height: 10vh;
    flex-direction: row;
    margin-top: -3rem;
}

.input{
    margin: 1rem;
    height: 3rem;
    width: 26rem;
    border-radius: 10px;
    border: 2px solid rgb(181, 176, 176);
}
.textArea{
    margin: 1rem;
    height: 10rem;
    border-radius: 10px;
    width: 26rem ;
    border: 2px solid rgb(181, 176, 176);
    resize: none;
}
.cancel{
    width: 10rem;
    height: 3rem;
    border-radius: 10px;
    margin-right:1rem ;
    border: none;
    background-color:  #FFECEC;
    
}
.cancel:hover{
    background-color: #FFECEC;
    border-radius: 10px;
}
.save{
    width: 10rem;
    height: 3rem;
    border-radius: 10px;
    background-color:#232674;
    color: white;
    
}
.save>svg{
    margin-left: 1rem;

}

.contents input{
    margin-top: 0;
}